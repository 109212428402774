@import "./MediaQueries.module.scss";
@import "./Variables.module.scss";
@import "./Font.module.scss";
@import "./Mixin.module.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow: hidden;
  font-family: $basefontFamily, sans-serif;
  line-height: $baseLineHeight;
  font-display: swap;
  margin: 0;
}

body {
  overflow: auto;
  width: 100vw;
  height: 100vh;
  position: relative;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  overflow-anchor: none;
  margin: 0;
}

ul,
ol {
  &:not(.css-default) {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

a {
  text-decoration: none;
  color: $pqActionColor;

  &:not([href]) {
    cursor: pointer;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin: 0;
}

picture {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
    width: auto;
  }
}

img {
  max-width: 100%;

  &[src$="svg"] {
    width: 100%;
  }

  &:not([src]) {
    display: none;
  }
}
