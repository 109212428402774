/***************************************************************************
// * iubenda Cookie Solution                                               *
// *                                                                       *
// * Sample CSS v2.3.0                                                     *
// *                                                                       *
// * (c) 2019 iubenda srl <info@iubenda.com>                               *
// * All Rights Reserved.                                                  *
// *                                                                       *
// *************************************************************************


/*
-----------------
IMPORTANT: Changing the following values may prevent the notice from being displayed properly.
-----------------
*/
#iubenda-cs-banner {
  font-size: 15px;
  background: none;
  line-height: 1.4;
  position: fixed;
  z-index: 99999998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  will-change: opacity;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  /* default */
}

#iubenda-cs-banner [class*=" iub"],
#iubenda-cs-banner [class^="iub"],
#iubenda-cs-banner .iubenda-banner-content:not(.iubenda-custom-content) * {
  font-size: 100%;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: -apple-system, sans-serif;
  text-decoration: none;
  color: currentColor;
  background-attachment: scroll;
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border: 0;
  border-color: #000;
  border-color: currentColor;
  border-radius: 0;
  border-style: none;
  border-width: medium;
  bottom: auto;
  clear: none;
  clip: auto;
  counter-increment: none;
  counter-reset: none;
  direction: inherit;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  height: auto;
  left: auto;
  letter-spacing: normal;
  line-height: inherit;
  list-style-type: inherit;
  list-style-position: outside;
  list-style-image: none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: 0;
  overflow: visible;
  padding: 0;
  position: static;
  quotes: "" "";
  right: auto;
  table-layout: auto;
  text-align: left;
  text-indent: 0;
  text-transform: none;
  top: auto;
  unicode-bidi: normal;
  vertical-align: baseline;
  white-space: normal;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  background-origin: padding-box;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto;
  -o-border-image: none;
  border-image: none;
  border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-column-count: auto;
  -moz-column-count: auto;
  column-count: auto;
  -webkit-column-gap: normal;
  -moz-column-gap: normal;
  column-gap: normal;
  -webkit-column-rule: medium none #000;
  -moz-column-rule: medium none #000;
  column-rule: medium none #000;
  -webkit-column-span: none;
  -moz-column-span: none;
  column-span: none;
  -webkit-column-width: auto;
  -moz-column-width: auto;
  column-width: auto;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  overflow-x: visible;
  overflow-y: visible;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  -webkit-perspective: none;
  perspective: none;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  text-shadow: none;
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform-style: flat;
  transform-style: flat;
  word-break: normal;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

#iubenda-cs-banner.iubenda-cs-overlay:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  pointer-events: auto;
}

#iubenda-cs-banner.iubenda-cs-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#iubenda-cs-banner.iubenda-cs-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#iubenda-cs-banner.iubenda-cs-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#iubenda-cs-banner.iubenda-cs-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#iubenda-cs-banner.iubenda-cs-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

#iubenda-cs-banner.iubenda-cs-visible {
  opacity: 1;
}

#iubenda-cs-banner.iubenda-cs-visible > * {
  pointer-events: auto;
}

#iubenda-cs-banner.iubenda-cs-slidein .iubenda-cs-container {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-top .iubenda-cs-container {
  -webkit-transform: translateY(-48px);
  -ms-transform: translateY(-48px);
  transform: translateY(-48px);
}

#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-bottom .iubenda-cs-container {
  -webkit-transform: translateY(48px);
  -ms-transform: translateY(48px);
  transform: translateY(48px);
}

#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-visible .iubenda-cs-container {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

#iubenda-cs-banner .iubenda-cs-container {
  position: relative;
  z-index: 2;
}

#iubenda-cs-banner .iubenda-cs-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#iubenda-cs-banner .iubenda-cs-brand > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#iubenda-cs-banner .iubenda-cs-brand img {
  max-width: 192px;
  max-height: 56px;
}

#iubenda-cs-banner .iubenda-cs-content {
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

#iubenda-cs-banner .iubenda-cs-rationale {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#iubenda-cs-banner .iubenda-cs-close-btn {
  position: absolute;
  top: -2px;
  padding: 16px;
  right: 0;
  min-width: 48px;
  height: 48px;
  font-size: 24px;
  line-height: 0;
  font-weight: lighter;
  cursor: pointer;
  text-align: center;
}

#iubenda-cs-banner .iubenda-cs-close-btn:hover {
  opacity: 0.5;
}

#iubenda-cs-banner .iubenda-banner-content {
  font-weight: 300;
  margin: 16px;
  //margin-bottom: 0 ;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px);
  //padding-bottom: 32px ;
}

#iubenda-cs-banner .iubenda-banner-content-padded {
  //padding-right: 32px ;
}

#iubenda-cs-banner .iubenda-banner-content a {
  cursor: pointer;
  color: currentColor;
  opacity: 0.7;
  text-decoration: underline;
}

#iubenda-cs-banner .iubenda-banner-content a:hover {
  opacity: 1;
}

#iubenda-cs-banner #iubenda-cs-title {
  font-weight: bold;
  margin-bottom: 16px;
}

#iubenda-cs-banner .iubenda-cs-opt-group {
  margin: 16px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#iubenda-cs-banner .iubenda-cs-opt-group > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 640px) {
  #iubenda-cs-banner .iubenda-cs-opt-group {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group-custom {
    margin-right: auto;
    -ms-flex-item-align: start;
    align-self: start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group-consent {
    margin-left: auto;
    -ms-flex-item-align: end;
    align-self: end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (max-width: 639px) {
  #iubenda-cs-banner .iubenda-cs-opt-group {
    margin: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group-custom {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  #iubenda-cs-banner .iubenda-cs-opt-group-consent {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

#iubenda-cs-banner .iubenda-cs-opt-group button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px 32px;
  border-radius: 64px;
  cursor: pointer;
  font-weight: bold;
  font-size: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
}

#iubenda-cs-banner .iubenda-cs-opt-group button:focus {
  opacity: 0.8;
}

#iubenda-cs-banner .iubenda-cs-opt-group button:hover {
  opacity: 0.5;
}

@media (min-width: 640px) {
  #iubenda-cs-banner .iubenda-cs-opt-group button:not(:last-of-type) {
    margin-right: 8px;
  }
}

@media (max-width: 639px) {
  #iubenda-cs-banner .iubenda-cs-opt-group button {
    padding: 8px 24px;
    width: 100%;
    display: block;
    text-align: center;
    margin: 6px 3px;
  }
}

#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand {
  margin: 0 -8px 0;
}

@media (max-width: 991px) {
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand {
    margin: -8px -8px 0;
  }
}

@media (min-width: 992px) {
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand div {
    margin: 0 auto;
    width: calc(992px - 32px);
  }
}

@media (max-width: 991px) {
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand div {
    margin: 0 8px;
  }
}

#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-container {
  width: 100%;
}

@media (min-width: 992px) {
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-rationale {
    width: 992px;
    margin: 16px auto;
  }
}

@media (max-width: 991px) {
  #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-content {
    padding: 8px;
  }
}

#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-brand {
  margin: -8px -8px 0;
}

#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-brand div {
  margin: 8px;
}

@media (min-width: 992px) {
  #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-container {
    width: 992px;
  }
}

@media (max-width: 991px) {
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center.iubenda-cs-top
    .iubenda-cs-container,
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center.iubenda-cs-bottom
    .iubenda-cs-container {
    width: 100%;
  }
}

@media (min-width: 640px) {
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
    .iubenda-cs-container,
  #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
    .iubenda-cs-container,
  #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
    .iubenda-cs-container {
    width: 480px;
  }
}

#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
  .iubenda-cs-opt-group,
#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
  .iubenda-cs-opt-group,
#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
  .iubenda-cs-opt-group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
  .iubenda-cs-opt-group
  > div,
#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
  .iubenda-cs-opt-group
  > div,
#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
  .iubenda-cs-opt-group
  > div {
  width: 100%;
}

#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
  .iubenda-cs-opt-group
  button,
#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
  .iubenda-cs-opt-group
  button,
#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
  .iubenda-cs-opt-group
  button {
  display: block;
  width: 100%;
  text-align: center;
}

#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
  .iubenda-cs-opt-group-custom,
#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
  .iubenda-cs-opt-group-custom,
#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
  .iubenda-cs-opt-group-custom {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center)
  .iubenda-cs-opt-group-consent,
#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center)
  .iubenda-cs-opt-group-consent,
#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom)
  .iubenda-cs-opt-group-consent {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content {
  -webkit-box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15);
  padding: 8px;
}

@media (min-width: 992px) {
  #iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content {
    border-radius: 4px;
    margin: 16px;
  }
}

#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-container,
#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-content,
#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-rationale {
  height: 100%;
}

#iubenda-cs-banner.iubenda-cs-fix-height.iubenda-cs-default-floating
  .iubenda-cs-content {
  height: calc(100% - 32px);
}

#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-brand img {
  max-width: 75%;
}

#iubenda-cs-banner .iubenda-cs-content {
  background-color: #626279;
  color: #ffffff;
}

#iubenda-cs-banner .iubenda-cs-opt-group {
  color: #000000;
}

#iubenda-cs-banner .iubenda-cs-opt-group button {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

#iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary {
  background-color: #0073ce;
  color: #ffffff;
}

.iubenda-tp-btn:not([data-tp-nostyle]) {
  font-size: 100%;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: -apple-system, sans-serif;
  text-decoration: none;
  color: currentColor;
  background-attachment: scroll;
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border: 0;
  border-color: #000;
  border-color: currentColor;
  border-radius: 0;
  border-style: none;
  border-width: medium;
  bottom: auto;
  clear: none;
  clip: auto;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: inherit;
  float: none;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  height: auto;
  left: auto;
  letter-spacing: normal;
  line-height: inherit;
  list-style-type: inherit;
  list-style-position: outside;
  list-style-image: none;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: 0;
  overflow: visible;
  padding: 0;
  position: static;
  quotes: "" "";
  right: auto;
  table-layout: auto;
  text-align: left;
  text-indent: 0;
  text-transform: none;
  top: auto;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: inherit;
  white-space: normal;
  width: auto;
  word-spacing: normal;
  z-index: auto;
  background-origin: padding-box;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto;
  -o-border-image: none;
  border-image: none;
  border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-column-count: auto;
  -moz-column-count: auto;
  column-count: auto;
  -webkit-column-gap: normal;
  -moz-column-gap: normal;
  column-gap: normal;
  -webkit-column-rule: medium none #000;
  -moz-column-rule: medium none #000;
  column-rule: medium none #000;
  -webkit-column-span: none;
  -moz-column-span: none;
  column-span: none;
  -webkit-column-width: auto;
  -moz-column-width: auto;
  column-width: auto;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  overflow-x: visible;
  overflow-y: visible;
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  -webkit-perspective: none;
  perspective: none;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  text-shadow: none;
  -webkit-transition: all 0s ease 0s;
  -o-transition: all 0s ease 0s;
  transition: all 0s ease 0s;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform-style: flat;
  transform-style: flat;
  word-break: normal;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  appearance: none;
  line-height: 34px;
  height: 34px;
  min-width: 34px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.65);
  background-color: white;
  display: inline-block;
  vertical-align: middle;
}

.iubenda-tp-btn[data-tp-icon] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%231CC691' fill-rule='evenodd' d='M16 7a4 4 0 0 1 2.627 7.016L19.5 25h-7l.873-10.984A4 4 0 0 1 16 7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: top 0.5px left 1px;
}

.iubenda-tp-btn[data-tp-circle] {
  border-radius: 32px;
}

.iubenda-tp-btn[data-tp-label]:after {
  content: attr(data-tp-label);
  padding: 0 16px;
  white-space: nowrap;
}

.iubenda-tp-btn[data-tp-label][data-tp-icon]:after {
  padding-left: calc(16px + 8px + 8px);
}

.iubenda-tp-btn[data-tp-float] {
  position: fixed;
  z-index: 2147483647;
}

.iubenda-tp-btn[data-tp-float]:not([data-tp-anchored]) {
  margin: 16px;
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored] {
  margin: 0 16px;
  border-radius: 6px;
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-left"],
.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-right"],
.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-left"],
.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-right"] {
  margin: 0;
  top: 75%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-left"],
.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-left"] {
  left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-right"],
.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-right"] {
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-left"],
.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-right"] {
  margin: 0;
  top: 50%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  -webkit-transform-origin: bottom;
  -ms-transform-origin: bottom;
  transform-origin: bottom;
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-left"] {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
}

.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-right"] {
  right: 0;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
  -webkit-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  transform-origin: right bottom;
}

.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"],
.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"] {
  bottom: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"][data-tp-anchored],
.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"][data-tp-anchored] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-float="top-left"],
.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"] {
  top: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-float="top-left"][data-tp-anchored],
.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"][data-tp-anchored] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-float="top-left"],
.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"] {
  left: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"],
.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"] {
  right: 0;
}

.iubenda-tp-btn[data-tp-float][data-tp-hover][data-tp-label]:after {
  max-width: 0;
  overflow: hidden;
  display: block;
  padding: 0;
  opacity: 0;
  -webkit-transition: max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease;
  -o-transition: max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease;
  transition: max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease;
}

.iubenda-tp-btn[data-tp-float][data-tp-hover][data-tp-label]:hover:after {
  max-width: 192px;
  padding-left: calc(16px + 8px + 8px);
  padding-right: 10px;
  opacity: 1;
}

/*
-----------------
MOBILE SDK
-----------------
*/

.iubenda-mobile-sdk #iubenda-cs-banner.iubenda-cs-visible {
  justify-content: center;
  align-items: center;
}
.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-container {
  max-height: 100%;
  display: flex;
}
.iubenda-mobile-sdk
  #iubenda-cs-banner
  .iubenda-cs-container
  .iubenda-cs-content {
  height: auto;
  border-radius: 0;
  margin: 0;
}
.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-opt-group {
  text-align: center;
}
.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-close-btn {
  display: none;
}
.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-rationale {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-banner-content {
  flex: 1;
  overflow-y: auto;
  margin: 0;
  padding: 16px 16px 48px;
  max-height: inherit;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 15%);
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 15%);
}

@media (min-width: 992px) {
  .iubenda-mobile-sdk #iubenda-cs-banner .iubenda-banner-content,
  .iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-opt-group {
    margin: 24px;
  }
  .iubenda-mobile-sdk
    #iubenda-cs-banner.iubenda-cs-default
    .iubenda-cs-rationale {
    width: 100%;
    margin: 0;
  }
}

.no-banner #iubenda-cs-banner {
  display: none;
}

/** fix scrolling in iOS 10 **/
.iubenda-mobile-sdk #iubenda-cs-banner.iubenda-cs-visible .iubenda-cs-container,
.iubenda-mobile-sdk
  #iubenda-cs-banner
  .iubenda-cs-container
  .iubenda-cs-content,
.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-rationale {
  height: 100%;
}
.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-top-container {
  position: relative;
}
.iubenda-mobile-sdk #iubenda-iframe #iab-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/** iframe **/
.iubenda-mobile-sdk #iubenda-iframe #iubenda-iframe-popup {
  height: 100%;
  width: 100%;
}
.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-close-btn {
  display: none;
}
.iubenda-mobile-sdk #iubenda-iframe {
  background-color: transparent;
}

/* Fix cookie policy trimming on older Android devices */
.iubenda-mobile-sdk #iubenda-iframe iframe {
  background-color: transparent;
  position: absolute;
  bottom: 0;
  top: 0;
}
.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-spinner {
  top: 50px;
}
/* Fix TCF widget overlapping footer in older Android devices */
.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-footer {
  z-index: 9999;
}
/* Fix popovers going behind footer buttons and not scrolling */
.iubenda-mobile-sdk #iubenda-iframe.iub-popover-visible .iub-popover {
  z-index: 10000;
}
.iubenda-mobile-sdk #iubenda-iframe .iub-popover-content {
  position: relative;
}
.iubenda-mobile-sdk #iubenda-iframe .iub-popover-content > div {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  bottom: 0;
}
/* Fix purposes widget not scrolling on older iOS and Android devices */
.iubenda-mobile-sdk #purposes-container {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

button.iubenda-cs-reject-btn.iubenda-cs-custom-close-btn {
  z-index: 1000 !important;
}

/* Avoid scrolling freezes when scrolling the banner from the accept button on iOS */
// html,
// body {
// 	height: 100%;
// 	overflow: hidden;
// }
