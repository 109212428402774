@import "./Variables.module.scss";
@import "./MediaQueries.module.scss";

#iubenda-cs-paragraph {
  padding-right: calc($basePadding / 2);
  padding-bottom: calc($basePadding / 2);
  overflow-y: scroll;
  max-height: 25vh;

  @include small() {
    max-height: 35vh;
  }
}

.iubenda-cs-custom-close-btn {
  font-size: 12px !important;
  border: 1px solid #fff !important;
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  padding: 5px 10px !important;
  border-radius: 55px !important;
  color: #fff !important;
  visibility: visible;
  cursor: pointer;

  &:hover {
    background-color: #777794 !important;
  }
}
