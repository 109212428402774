@import "./Variables.module.scss";
@import "./Font.module.scss";
@import "./Palette.module.scss";

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: calc($smallSpacing / 2);
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: calc($smallSpacing / 2);
}

input,
select {
  display: block;
  font-family: $basefontFamily;
  font-size: $basefontSize;
}

// [type='color'],
// [type='date'],
// [type='datetime'],
// [type='datetime-local'],
// [type='email'],
// [type='month'],
// [type='number'],
// [type='password'],
// [type='search'],
// [type='tel'],
// [type='text'],
// [type='time'],
// [type='url'],
// [type='week'],
// input:not([type]),
// textarea,
// select[multiple] {
// 	background-color: $pqBgSecondaryColor;
// 	border: $baseBorder;
// 	border-radius: $baseBorderRadius;
// 	box-shadow: $formBoxShadow;
// 	box-sizing: border-box;
// 	font-family: $basefontFamily;
// 	font-size: $basefontSize;
// 	padding: $baseSpacing;
// 	transition: borderColor $baseAnimationDuration $baseAnimationTimingFunction;
// 	width: 100%;
// 	height: $baseTextInputsHeight;

// 	&:hover {
// 		border-color: shade($pqBaseBorderColor, 20%);
// 	}

// 	&:focus {
// 		outline: none;
// 	}

// 	&:disabled {
// 		background-color: shade($pqBgSecondaryColor, 5%);
// 		cursor: not-allowed;

// 		&:hover {
// 			border: $baseBorder;
// 		}
// 	}
// }

textarea {
  resize: vertical;
}

[type="search"] {
  appearance: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: calc($smallSpacing / 2);
}

[type="file"] {
  margin-bottom: $smallSpacing;
  width: 100%;
}

select {
  margin-bottom: $baseSpacing;
  max-width: 100%;
  width: auto;
}
